  .ant-layout.ant-layout-has-sider{min-height: 100vh;}
  .test{background-color:#fff;}
  .homePage{height: 100vh;}
  .app { min-height: 100vh;}
  .app .pointer { cursor: pointer;}
  .app a { cursor: pointer;display: inline-block;white-space: nowrap;}
  .app hr { height: 1px; border: none; background-color: #e8e8e8;}
  .app .middle { display: flex; justify-content: center; align-items: center;}
  .app .ph100 { height: 100%;}
  .app .colorRed { color: red;}
  .app .form { padding: 0px;}
  .app .form .ant-form-item { display: flex; margin-bottom: 16px;}
  .app .form .ant-form-item-control-wrapper { flex: 1;}
  .app .pagination-right { display: flex; margin: 16px 0px; justify-content: flex-end;}
  .app .ant-pagination-options-size-changer.ant-select { margin-right: 0px;}
  .ant-select{min-width: 20%;}
  .top {
    background-color: #ddf;
    color: #333;
    border: 1px solid silver;
    box-shadow: 3px 4px 3px 0px silver;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 5px;
    overflow: hidden;
    line-height: 20px;
}
  .top input[type="text"] {
    height: 25px;
    border: 0;
    padding-left: 5px;
    width: 280px;
    border-radius: 3px;
    outline: none;
}
  


  /* 底部+头部+侧边栏 */
  .footer {text-align: center;padding: 24px 0px;}
  .ant-layout-header {height: 64px; padding: 0 !important; color: rgba(0, 0, 0, 0.65);line-height: 64px;background-color: rgba(255,255,255,1) !important;}
  .header {margin-left:200px;padding: 0;box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);position: fixed;display: flex;justify-content: space-between;height: 64px;z-index: 9;align-items: center;background-color: #fff;width:100%;padding-right: 200px !important;}
  .header .rightcon {display: flex;}
  .header .trigger {font-size: 18px;line-height: 64px;padding: 0 24px;cursor: pointer; transition: color 0.3s;}
  .header .trigger:hover {color: #1890ff;}
  .header.fixed {position: fixed;top: 0;right: 0;width: calc(100% - 256px); z-index: 29; transition: width 0.2s;}
  .header.fixed.collapsed {width: calc(100% - 80px);}
  .header .ant-menu-submenu-title {height: 64px;}
  .header .ant-menu-horizontal {line-height: 64px;}
  .header .ant-menu-horizontal > .ant-menu-submenu:hover {background-color: #e6e6e6;}
  .header .ant-menu {border-bottom: none;height: 64px;}
  .header .ant-menu-horizontal > .ant-menu-submenu {top: 0;margin-top: 0;}
  .header .ant-menu-horizontal > .ant-menu-item,
  .header .ant-menu-horizontal > .ant-menu-submenu {border-bottom: none;}
  .header .ant-menu-horizontal > .ant-menu-item-active,
  .header .ant-menu-horizontal > .ant-menu-item-open,
  .header .ant-menu-horizontal > .ant-menu-item-selected,
  .header .ant-menu-horizontal > .ant-menu-item:hover,
  .header .ant-menu-horizontal > .ant-menu-submenu-active,
  .header .ant-menu-horizontal > .ant-menu-submenu-open,
  .header .ant-menu-horizontal > .ant-menu-submenu-selected,
  .header .ant-menu-horizontal > .ant-menu-submenu:hover {border-bottom: none;}
  .header .rightContainer {display: flex;align-items: center;}
  .header .button {width: 64px;height: 64px;line-height: 64px;text-align: center;font-size: 18px;cursor: pointer;}
  .iconButton {width: 48px;height: 48px;display: flex;justify-content: center;align-items: center;border-radius: 24px;cursor: pointer;}
  .iconButton + .iconButton {margin-left: 8px;}
  .iconButton .iconFont {color: #b2b0c7;font-size: 24px;}
  @media (max-width: 767px) {.header {width: 100% !important;}}
  .sider{display: flex;align-items: center;justify-content: center;position: fixed !important ;height: 100vh !important;overflow-y: auto;}
  .sider .logo {height: 64px;line-height: 64px;padding-left: 24px;font-size: 18px;font-weight: 500;color: #fff;overflow: hidden; word-break: keep-all;white-space: nowrap;text-overflow: ellipsis;}
  .sider .logo img {margin-right: 8px;width: 32px;height: 32px;border-radius: 16px;}
  .sider .hide {display: none;}
 
  /* uploadimage */
  /* 配合样式可以做出上传按钮和示例效果 */
  .ant-upload.ant-upload-select-picture-card > .ant-upload{display:flex !important;flex-direction: column;justify-content: center;}
  .ant-upload-select-picture-card i {font-size: 28px;color: #999;}
  .ant-upload-select-picture-card .ant-upload-text { font-size: 12px;color: #666;}
  .upload-example {position: relative;display: inline-block;height: 96px; width: 96px;padding: 8px;border: 1px solid #d9d9d9;border-radius: 6px;vertical-align: top;}
  .upload-example img {height: 78px;width: 78px;}
  .upload-example:before {position: absolute;bottom: 8px;left: 8px;content: ' ';width: 78px;height: 24px;background-color: #808080;opacity: .8;}
  .upload-example span {position: absolute;bottom: 8px;left: 8px;width: 78px;height: 24px;color: #fff;line-height: 24px;text-align: center;}
  .img{width: 100%;margin-top: 30px;}

  /* loginform */
  #login-page{position: fixed;display: flex;justify-content: center;align-items: center;top: 0;left: 0;width: 100%;height: 100%;background:#DEF3F4;z-index: 99;}
  #login-page .container{background-color: rgba(255, 255, 255, 0.97);padding: 50px 60px 20px;border-radius: 4px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);box-shadow: rgb(0, 0, 0) 0px 0px 10px;}
  #login-page .box{position: absolute;top: 0;left: 0;width: 100%;height: 100%;padding: 90px 40px 40px 40px;backface-visibility: hidden;background: linear-gradient(230deg, rgba(53, 57, 74, 0) 0%, rgb(0, 0, 0) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(53, 57, 74, 0)', endColorstr='rgb(0, 0, 0)',GradientType=1 );box-shadow: -15px 15px 15px rgba(0,0,0,.4);transition: all 1s;}
  #login-page .showBox{transform:rotateY(0deg);}
  #login-page .hiddenBox{transform:rotateY(180deg);}
  #login-page .loginBtn{border: 1px solid #37dc74;border-radius: 2px;font-size: 11px;color: white;transition: all .2s;}
  #login-page .bottom{display: flex;height: 42px;justify-content: space-between;align-items: center;}
  #login-page .registerBtn{color: #D3D7F7;}
  #login-page .registerBtn:hover{color: #37dc74;cursor: pointer;}
  #login-page .loginBtn:hover{color: white;background: #37dc74;cursor: pointer;}
  #login-page .title{height: 60px;color:#D3D7F7;font-size: 16px;margin-bottom: 0;}
  #login-page .footer{margin-top: 10px;    font-size: 16px;    color: #ccc;    text-align: center;}
  /*覆盖antd的默认样式*/
  #login-page input{color:#333;outline: none;box-shadow: none;background: transparent;}
  #login-page .ant-input-group-addon{background: transparent;padding:0;border: none;color: #fff;opacity: 0.8;}
  #login-page .ant-input-group-addon .iconfont{display: inline-block;width: 30px;transition: all .3s;opacity: 0.6;}
  #login-page .ant-form{margin-top: 30px;}
  #login-page .ant-form-item{}
  #login-page .ant-form-explain{position: absolute;z-index: 99;left:110%;top:0;height: 41px;/*box-shadow: -2px 2px 2px rgba(0,0,0,.3);*/}
  .login-notification .ant-notification-notice-message{color:#D3D7F7;}
  .login-notification .ant-notification-notice-close{color:#D3D7F7;}
  /*更改谷歌浏览器input背景*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {/*延迟背景颜色载入*/-webkit-transition-delay: 99999s;-webkit-transition: color 99999s ease-out, background-color 99999s ease-out;}
  .focus { transform: scale(0.7);width: 20px;opacity: 1}
  .loadingBox {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%)}
  .loginPage {margin-top: 100px;font-size: 16px;color: #333;text-align: center;}
  .loginBtn {margin-left: 10px;font-size: 12px;color: #fff;width: 90px;}
  .noLoginBtn {margin-left: 10px;font-size: 12px;background-color: #ccc;color: #999;border-width: 0;width: 90px;}
  .backgroundBox { position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; background-attachment:fixed; background-image: url(../img/ico_03.jpg); transition:all .5s; background-color:#f8f9fa; filter:"progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";  
    -moz-background-size:100% auto;        background-size:100% auto;  };
  .focus { width: 200px; opacity: 1};
  .loadingBox { background-color:rgba(255,255,255,0.97); padding:50px 100px 30px 120px; border-radius:4px; position:fixed; top:40%; left:50%; transform:translate(-50%,-50%); box-shadow:0 0 10px #000;};
  .loadingTitle { position:fixed; top:50%; left:50%; margin-left: -45; margin-top: -18; color:#000; font-weight:500; font-size:24px;}
  .loginPage {position: flex;top: 20%;left: 30%;}

  /* cancelForm */
  .cancelForm {width: 100%;height: 200px;padding: 8px;border: 1px solid #e8e8e8;}
  .cancelForm{  min-width: 64%;  height: 200px;  margin-right: 30px;  padding: 0;  font-variant: tabular-nums;  list-style: none;  font-feature-settings: 'tnum';  position: relative;  display: inline-block;  padding: 4px 11px;  padding-right: 11px;  color: rgba(0, 0, 0, 0.65);  font-size: 14px;  line-height: 1.5;  background-color: #fff;  background-image: none;  border: 1px solid #d9d9d9;  border-top-color: rgb(217, 217, 217);  border-right-color: rgb(217, 217, 217);  border-right-width: 1px;  border-bottom-color: rgb(217, 217, 217);  border-left-color: rgb(217, 217, 217);  border-radius: 2px;  transition: all 0.3s;}
  .cancelForm:hover{border-color: #4641b5;border-right-width: 1px !important;}
  .cancelForm::placeholder{color: #bfbfbf;}
  .cancelForm:focus{border-color: #4641b5;outline: -webkit-focus-ring-color auto 0;}

  /* express */
  .fahuo{display: flex;flex-direction: column;height: 100px;justify-content: space-around;}
  .fahuo .ipt{margin-right: 30px;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';position: relative;width: 100%;display: inline-block;height: 32px;padding: 4px 11px;padding-right: 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9;border-top-color: rgb(217, 217, 217);border-right-color: rgb(217, 217, 217);border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .fahuo .ipt:hover {border-color: #4641b5;border-right-width: 1px !important;}
  .fahuo .ipt::placeholder{color: #bfbfbf;}
  .fahuo .ipt:focus{border-color: #4641b5 !important;outline: -webkit-focus-ring-color auto 0;}

  /* list+search+tableblock */
  .between {margin: 16px 0px;display: flex;justify-content: space-between;align-items: center;}
  .list {width:100%}
  .content .body {padding: 24px;background-color: #fff;display: flex;flex-direction: column;justify-content: flex-start;}
  .content .body .bread {padding-bottom: 16px;}
  .content .body .btn {width: max-content;margin-bottom: 20px;}
  .search{text-align: left;}
  .search .date{margin-right: 30px;}
  .search .ipt{margin-bottom: 10px;box-sizing: border-box;margin-right: 15px;margin-left: 15px;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';position: relative;display: inline-block;width: 200px;height: 32px;padding: 4px 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9;    border-top-color: rgb(217, 217, 217);    border-right-color: rgb(217, 217, 217);    border-right-width: 1px;    border-bottom-color: rgb(217, 217, 217);    border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .search .ipt:hover{border-color: #4641b5;border-right-width: 1px !important;}
  .search .ipt:focus{border-color: #4641b5;outline: -webkit-focus-ring-color auto 0;}
  .search .ipt::placeholder{color: #bfbfbf;}
  .search .ipt:first-child{margin-left: 0;}
  .body .ipt1{width: 50px;text-align: center;box-sizing: border-box;margin: 0;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';position: relative;display: inline-block;height: 32px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9;    border-top-color: rgb(217, 217, 217);    border-right-color: rgb(217, 217, 217);    border-right-width: 1px;    border-bottom-color: rgb(217, 217, 217);    border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .body .ipt1:hover{border-color: #4641b5;border-right-width: 1px !important;}
  .body .ipt1:focus{border-color: #4641b5;outline: -webkit-focus-ring-color auto 0;}
  .body .ipt1::placeholder{color: #bfbfbf;}
  .body .ipt1::-webkit-inner-spin-button {-webkit-appearance: none;}
  .body .ipt1::-webkit-outer-spin-button {-webkit-appearance: none;}
  .body input[type="number"]{  -moz-appearance: textfield;}
  .body hr{margin: 20px 0;}
  .body .bread .account{padding:30px;background: rgba(245,34,45,0.04);border: 1px solid #4641b5;border-radius: 2px;font-family: Arial, Helvetica, sans-serif;display: flex;align-items: center;}
  .body .bread .tittle{font-size: 20px;font-weight: bold;}
  .body .bread .ico{background-size: cover;width: 32px;height: 32px;margin-right: 10px;}
  .body .bread .number{font-size: 20px;font-weight: bold;}

  /* form */
  .content .header {padding: 12px 24px;background-color: #fff;}
  .content .header .title {margin-top: 8px;font-size: 20px;font-weight: 600;color: rgba(0, 0, 0, 0.85);}
  .content .body {display: flex;justify-content: space-between;margin: 24px 24px 0;padding: 24px;background-color: #fff;}
  .content .body .input_con {width: 450px;}
  .content .body .text_con {width: calc(-362%);}
  .from{width: 100%;box-sizing: border-box;}
  .from-double{display: flex;flex-wrap: wrap;}
  .from .items{margin-top: 20px;min-width: 50%;max-width: 100%;}
  .from .items-btn{margin-top: 20px;width: 100%;}
  .from .items textarea{min-width: 50%;margin-right: 30px; min-height: 32px;}
  .from .items input{margin-right: 30px;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';display: inline-block;min-width: 50%;height: 32px;padding: 4px 11px;padding-right: 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9;border-top-color: rgb(217, 217, 217);border-right-color: rgb(217, 217, 217);border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .from .items input:hover {border-color: #4641b5;border-right-width: 1px !important;}
  .from .items input:focus{border-color: #4641b5;outline: -webkit-focus-ring-color auto 0;}
  .from .items input::placeholder{color: #bfbfbf;}
  .from .items select{margin-right: 30px;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';display: inline-block;min-width: 50%;height: 32px;padding: 4px 11px;padding-right: 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9;border-top-color: rgb(217, 217, 217);border-right-color: rgb(217, 217, 217);border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .from .items select:hover {border-color: #4641b5;border-right-width: 1px !important;}
  .from .items select:focus{border-color: #4641b5;outline: -webkit-focus-ring-color auto 0;}
  .from .items select::placeholder{color: #bfbfbf;}
  .from .items .btn{color: #fff;background-color: #4641b5;line-height: 1.499;text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);position: relative;display: inline-block;font-weight: 400;white-space: nowrap;text-align: center;background-image: none;border: 1px solid transparent;border-top-color: transparent;border-right-color: transparent;border-bottom-color: transparent;border-left-color: transparent;box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);cursor: pointer;transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);user-select: none;touch-action: manipulation;height: 32px;padding: 0 15px;font-size: 14px;border-radius: 2px;}
  .from .items .btn:hover{color: #fff;  background-color: #4641b5;  border-color: #4641b5;}
  .from .items .btn:focus{border-color: #4641b5;outline: -webkit-focus-ring-color auto 0;}
  .from div label{height: 32px;line-height: 32px;display: inline-block;}
  .from div label input{width: 16px;height: 16px;}
  .from table{width: 100%;border: 1px solid #d9d9d9;margin-top: 30px;}
  .from table .th{white-space: nowrap;;width:max-content;border: 1px solid #d9d9d9;color: rgba(0, 0, 0, 0.85);padding: 16px;font-weight: 500;text-align: left;background: #fafafa;border-bottom: 1px solid #e8e8e8;transition: background 0.3s ease;}
  .from table .td{max-width: 200px;border: 1px solid #d9d9d9;padding:16px;word-break: break-all;word-wrap: break-word;}
  .from .items{display: flex;align-items: center;}
  .from .items .tittle{width: 100px;}
  .from .items .tittle .place span{width: 100%;}
  .from .img{width:max-content;display: flex;align-items: center;margin-top: 20px;min-width: 50%;max-width: 100%;}
  .from .img span{display: flex;align-items: center;width: max-content;}
  .from .img .kong{width: 100px;}
  .from div .ipt{margin-right: 30px; padding: 0; font-variant: tabular-nums; list-style: none;font-feature-settings: 'tnum'; position: relative; display: inline-block; min-width: 300px; height: 32px; padding: 4px 11px; padding-right: 11px; color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9; border-top-color: rgb(217, 217, 217); border-right-color: rgb(217, 217, 217); border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .from div .ipt:hover {border-color: #4641b5;border-right-width: 1px !important;}
  .from .btn{line-height: 1.499;margin-right: 55%;color: #fff;margin-top: 20px; background-color: #4641b5;border-color: #4641b5; text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12); box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);position: relative;display: inline-block; font-weight: 400; white-space: nowrap;text-align: center; background-image: none; border: 1px solid transparent; border-top-color: transparent; border-right-color: transparent; border-bottom-color: transparent; border-left-color: transparent; box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015); cursor: pointer;transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); user-select: none; touch-action: manipulation; height: 32px; padding: 0 15px; font-size: 14px;border-radius: 2px;}
  .from .btn:hover{color: #fff; background-color: #4641b5; border-color: #4641b5;}
  .from .btn:focus{border-color: #4641b5 !important;outline: -webkit-focus-ring-color auto 0;}
  .from table{width: 100%;border: 1px solid #d9d9d9;margin-top: 30px;}
  .from table .th{border: 1px solid #d9d9d9;color: rgba(0, 0, 0, 0.85);padding: 16px; font-weight: 500; text-align: left; background: #fafafa; border-bottom: 1px solid #e8e8e8; transition: background 0.3s ease; min-width: 80px; word-break: break-all; white-space: nowrap;}
  .from table .td{border: 1px solid #d9d9d9;color: rgba(0, 0, 0, 0.85);padding: 16px; font-weight: 500; text-align: left; border-bottom: 1px solid #e8e8e8; transition: background 0.3s ease; min-width: 10% !important; max-width: 20%; text-align: justify; word-break: break-all;}
  .from table td{border: 1px solid #d9d9d9;padding:16px;max-width: 10%;}
  .from .select{display: flex;align-items: center;margin-top: 20px;}
  .from .select .tittle{width: 100px;}
  .from .select label{display: flex;align-items: center;}
  .from .select div{display: flex;align-items: center;}
  .from .select .kong{width: 100px;text-align: justify !important;}
  .from .select input{display: none !important;}
  .from hr{margin-top: 20px;}
  .content .body .titles .title{font-size: 20px;font-weight: bold;color: #333;}


  .pop{height: 500px;overflow-y: auto;}
  .pop .items{margin-top: 20px;display: flex;align-items: center;}
  .pop::-webkit-scrollbar{/*滚动条整体样式*/ width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/ height: 1px;}
  .pop::-webkit-scrollbar-thumb {/*滚动条里面小方块*/ border-radius: 10px; -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1); background: #bfbfbf;}
  .pop::-webkit-scrollbar-track {/*滚动条里面轨道*/ -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1); border-radius: 10px; background: #EDEDED;}
  .pop div .ipt{ margin-right: 30px; padding: 0; font-variant: tabular-nums; list-style: none; font-feature-settings: 'tnum'; position: relative; display: inline-block; min-width: 300px; height: 32px; padding: 4px 11px; padding-right: 11px; color: rgba(0, 0, 0, 0.65); font-size: 14px; line-height: 1.5; background-color: #fff; background-image: none; border: 1px solid #d9d9d9; border-top-color: rgb(217, 217, 217); border-right-color: rgb(217, 217, 217); border-right-width: 1px; border-bottom-color: rgb(217, 217, 217); border-left-color: rgb(217, 217, 217); border-radius: 2px; transition: all 0.3s;}
  .pop div .ipt:hover {border-color: #4641b5;border-right-width: 1px !important;}
  .pop .select{display: flex;align-items: center;margin-top: 20px;}
  .pop .select label{display: flex;align-items: center;}
  .pop .select div{display: flex;align-items: center;}
  .pop .select .kong{width: 100px;text-align: justify !important;}
  .pop .select input{display: none !important;}
  .pop .img{display: flex;align-items: center;}
  .pop .img .kong{width: 100px;}
  .pop .img span{width: max-content;display: flex;align-items: center;}
  .pop input:focus{border-color: #4641b5 !important;outline: -webkit-focus-ring-color auto 0;}
  .content .editor{display:inline-block;width: 800px;}
  .from .editor{ min-width: 64%; /* margin-right: 30px; */ padding: 0; font-variant: tabular-nums; list-style: none; font-feature-settings: 'tnum'; position: relative; display: inline-block; padding: 4px 11px; padding-right: 11px; color: rgba(0, 0, 0, 0.65); font-size: 14px; line-height: 1.5; background-color: #fff; background-image: none; border: 1px solid #d9d9d9; border-top-color: rgb(217, 217, 217); border-right-color: rgb(217, 217, 217); border-right-width: 1px; border-bottom-color: rgb(217, 217, 217); border-left-color: rgb(217, 217, 217); border-radius: 2px; transition: all 0.3s;}
  .from .editor .dropdown-handler{color: #333;background-color: #fff;}
  .shangchuan{width: max-content !important;}
  .shangchuan div{margin: 0 !important;padding: 0 !important;border: none !important;width: max-content !important;height: max-content !important;}
  .shangchuan span{margin: 0 !important;padding: 0 !important;background:#fff !important;width: 100px !important;}
  .shangchuan div span{width: max-content !important;}
  .shangchuan .tupian{ margin: 0; padding: 0;}
  .from .select{display: flex;align-items: center;margin-top: 20px;}
  .from .select label{display: flex;align-items: center;}
  .from .select div{display: flex;align-items: center;}
  .from .select .kong{width: 100px;text-align: justify !important;}
  .from .select input{display: none !important;}
  .items .shuru{white-space: nowrap;box-sizing: border-box;width: 300px;}
  .items .kong{width: 100px;text-align: justify !important;}
  .items .txt{min-width: 64%;height: 200px;margin-right: 30px;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';position: relative;display: inline-block;padding: 4px 11px;padding-right: 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5; background-color: #fff;background-image: none;border: 1px solid #d9d9d9;border-top-color: rgb(217, 217, 217);border-right-color: rgb(217, 217, 217);border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217); border-radius: 2px;transition: all 0.3s;}
  .items .txt:hover{border-color: #4641b5;border-right-width: 1px !important;}
  .items .txt::placeholder{color: #bfbfbf;}
  .items .txt:focus{border-color: #4641b5;outline: -webkit-focus-ring-color auto 0;}

  /*首页样式*/
  .test{width: 100%;text-align: center;min-height: 80vh;margin: 20px;display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;}
  .test h1{font-size: 40px;font-weight: bold;}
  .test h1 span{font-size: 16px;font-weight: normal;}
  .test .icon{width: 100%;}
  .test .foot{color: #ccc;}

  /*添加快递员*/
  .from .items .address{width: 50%;margin: 0;}
  .from .items .address .th{color: rgba(0, 0, 0, 0.85);font-weight: 500;text-align: left;background: #fafafa;position: relative;padding: 16px 16px;overflow-wrap: break-word;}
  .from .items .address .th th{padding: 16px 16px;overflow-wrap: break-word;border: 1px solid #f0f0f0;}
  .from .items .address .td td{padding: 8px 16px;overflow-wrap: break-word;border: 1px solid #f0f0f0;}
  .from .items .address .td input{padding: 0;margin: 0;display: block;width: 16px;}
  
  /*分配快递员*/
  .fahuo .item{margin-bottom: 5px;}
  .fahuo .item .title{width: 90px;display: inline-block;}
  /* .fahuo .select{display: flex;} */
  .fahuo .select .ant-select{width: 80%;display: inline-block;}
  .fahuo .select .title{width: 90px;display: inline-block;}

  /*面包屑*/
  .mbx{padding:0 0 0 20px !important;margin-top: 20px !important;}
  
  /** 平台参数设置 */
  .from .ht{height:40px;line-height:40px;/* background:#f0f0f0; */margin:40px 0 0;border-bottom:1px solid #f0f0f0;width: 100%;}
  .from .ht:first-child{margin:0;}
  .from .ht h3{display: inline-block;line-height:40px;font-size:16px;color:#333;font-weight: bold;}

  .from .items .settingTittle{width: 200px;}

  .uploadText{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .feedbackContent{
    word-wrap: 'break-word';
    word-break: 'break-word';
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .posterBg{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .posterBgImage{
    width: '100%';
    height: 125px;
  }
  .posterRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left:30px;
    padding-right: 30px;
    margin: 10px 0;
    color: #fff;    
    font-size: 25px;
  }
  .posterRow1{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 20px;
  }
  .posterTipText1{
    font-size: 16px;
  }
  .posterItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .posterImage{
    width: 140px;
    height: 140px;
    border-radius: 10px;
    object-fit:cover;
  }
  .posterTags{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .posterTag{
    background-color: #35C23F;
    color:#fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-right: 10px;
    padding:1px 15px 1px 10px;
    font-size: 12px;
  }
  .row{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .rowPrice{
    color: #4641b5;
    margin-right: 20px;
    font-size: 20px;
  }
  .rowPriceB{
    color: #999;
    text-decoration: line-through;
  }
  .posterLocation{
    width: 20px;
    height: 20px;
  }
  .posterBtn{
    text-align: center;
    z-index: 999;
    margin-top: 20px;
  }
  .posterCancel{
    padding: 2px 20px 2px 20px;
    margin-right: 30px;
  }
  .posterSure{
    padding: 2px 20px 2px 20px;
    color: #fff;
    background-color: #37dc74;
    border-color: #37dc74;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .posterList{
    background-color: #fff;
    padding:15px;
    margin: 20px;
    border-radius: 10px;
  }
  .posterInfo{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 140px;
  }
  .posterQr{
    width: 120px;
    height: 120px;
    border-radius: 10px;
  }
  .posterTitle{
    font-size: 16px;
    font-weight: bold;
  }
  .ant-modal{
    padding-bottom: 0 !important;
  }
  .posterImageDiv{
    width: 140px;
    height: 140px;
  }
  .imagePicker{width: 104px;height: 104px; border:1px solid #ebebeb;margin: 0 8px 8px 0;}
.imagePicker .image{width: 100%;height: 100%;}
.uploadText{position: relative;margin-bottom: 1rem;}
.simpleRow{display: flex;flex-direction: row;align-items: center;}
.simpleRow ul{display: flex;flex-direction: row;align-items: center;padding-left: 0;}


.ant-layout-sider{    background: #4641b5 !important;width: 170px !important;min-width: 170px !important;}
.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub{background: #4641b5 !important;}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{background: #ffffff44 !important;}
.ant-tabs-nav .ant-tabs-tab-active{color: #4641b5 !important;}
.ant-tabs-ink-bar{background: #4641b5 !important;}
.ant-btn-primary{background: #4641b5 !important; border-color: #4641b5 !important;}
a {color: #4641b5 !important;}
.ant-picker-focused{border-color: #4641b5 !important;box-shadow: 0 0 0 2px rgba(9, 204, 58, 0.15) !important ;}
.ant-picker:hover, .ant-picker-focused{    border-color: #4641b5 !important;}
.sider .logo{font-size: 14px;padding: 0;display: flex;align-items: center;justify-content: center;}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{border-color: #4641b5 !important;}
.ant-input:hover{border-color: #4641b5 !important;}
.ant-cascader-picker:focus .ant-cascader-input{box-shadow: 0 0 0 2px rgba(18, 223, 86, 0.15) !important;}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover{background-color: rgba(18, 228, 88, 0.15) !important;}
.sider .logo img{width: 22px;height: 22px;}
/* .sider .logo span{font-size: 14px;} */
.header{margin-left: 170px;}
.ant-layout-content{margin-left: 170px;margin-top: 70px;}
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{border-color: #4641b5 !important;box-shadow: 0 0 0 2px rgba(18, 223, 86, 0.15) !important;}
::selection{background: #4641b5 !important;}
.ant-input:focus, .ant-input-focused{box-shadow: 0 0 0 2px rgba(18, 223, 86, 0.15) !important;border-color: #4641b5 !important;}
.ant-cascader-picker-label:hover + .ant-cascader-input{border-color: #4641b5 !important;}
.ant-tabs-nav .ant-tabs-tab:hover{color: #ffffff44 !important;}
.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{color: #fff !important;}
.ant-cascader-picker:focus .ant-cascader-input{border-color: #4641b5 !important;}
.dingdan .ant-table-row-level-0{background-color: #4641b510;}

/* 弹窗*/
.well .table .tb-item td .ipt .strong{width: auto;height: 32px;border: 1px solid #e6e6e6;border-radius: 2px;}
.well {
  background-color: #fff;
  border: 1px solid #ebebeb;
  -webkit-box-shadow: 1px 1px 0px #ffffff;
  -moz-box-shadow: 1px 1px 0px #ffffff;
  box-shadow: 1px 1px 0px #ffffff;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 6px;
  
}

.table {
  width: 100%;
}
table.table.list tr:first-child td {
  border-top: 0px;
}
table .whole-tr{
	background-color: #f0f0f0;
}

table .whole-tr .name{
	width: 300px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display:inline-block;
}
.block-body table {
  margin-top: 12px;
}
.teshuA{
  color: #fff !important;
}
.oldContent{
  background-color: #2222
}
.newContent{
  background-color: #2222
}
.ant-table{font-size: 12px!important;}
.addA{color: #fff !important;}

.biTian::before{
  content: "*";
  color: red;
  font-size: 20px;
  position: absolute;
  left: -15px;
}
.biTian{
  position: relative;
}